/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, customMeta }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            index
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  if (!Array.isArray(customMeta)) {
    customMeta = []
  }

  const additionalMeta = customMeta.map(({ tag, attributes }, i) => {
    return React.createElement(tag, attributes.reduce((carry, attribute) => {
      carry[attribute.key] = attribute.value

      return carry
    }, { key: i }))
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: 'robots',
          content: site.siteMetadata.index === true ? 'index,follow' : 'noindex,nofollow',
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <title id="title">{title}</title>
      {additionalMeta}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `it-IT`,
  meta: [],
  customMeta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
